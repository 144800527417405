<template>
  <div class="queryDetails">
    <!-- 简易订餐 -->
    <van-nav-bar :title="'菜单'" />
    <div class="overflow_div">
      <van-image
        :src="pic"
        height="100%"
        width="100%"
        fit="contain"
        @click="showBig"
      />
    </div>
    <div class="footer">
      <van-button type="info" size="small" @click="goto">开始订餐</van-button>
    </div>
  </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant'
export default {
  data() {
    const userInfo = localStorage.userInfo
      ? JSON.parse(localStorage.userInfo)
      : {}
    const pic = this.$route.query.pic
    return {
      userInfo,
      pic // 从外部过来的参数
    }
  },
  created() {},
  mounted() {},
  methods: {
    // 根据日期获取年份列表
    goto() {
      this.$router.push({
        path: '/simple',
        query: { from: 'home', fromH5: true, name: name }
      })
    },
    showBig() {
      ImagePreview({
        images: [this.pic],
        closeable: true
      })
    }
  }
}
</script>

<style scoped lang="less">
.queryDetails {
  height: 100vh;
  overflow: hidden;
  // background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    background: #f6f6f6;
    height: calc(100% - 200px);
    text-align: center;
    .van-image {
      /deep/ .van-image__img {
        object-position: center top;
      }
    }
  }
  .footer {
    position: absolute;
    background-color: #ffffff;
    bottom: 0;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    /* text-align: center; */
    justify-content: center;
    .van-button {
      width: 95%;
      border-radius: 12px;
      .van-button__text {
        font-size: 36px;
        font-family: Source Han Sans SC;
        font-weight: 500;
      }
    }
  }
}
</style>
